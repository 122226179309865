const Sentry = require('@sentry/react');

export const initializeSentry = () => {
    try {
      if (!process.env.POI_APP_SENTRY_ORG || !process.env.POI_APP_SENTRY_PROJECT) {
        logger.error('Required env vars are not defined to initialize Sentry');
        return;
      }
  
      Sentry.init({
        dsn: `https://${process.env.POI_APP_SENTRY_ORG}.ingest.us.sentry.io/${process.env.POI_APP_SENTRY_PROJECT}`,
        tracesSampler: ({ name, attributes, parentSampled }) => {
          if (name.includes('healthcheck')) {
            return 0;
          }
  
          if (name.includes('auth')) {
            return 1;
          }
  
          if (name.includes('comment')) {
            return 0.01;
          }
  
          if (typeof parentSampled === 'boolean') {
            return parentSampled;
          }
  
          return 0.5;
        },
        tracesSampleRate: parseFloat(
          process.env.POI_APP_SENTRY_ENVIRONMENT === 'development' ? 0.8 : 0.2,
        ),
        environment: process.env.POI_APP_SENTRY_ENVIRONMENT,
      });
    } catch (error) {
      logger.error('Failed to initialize Sentry:', error.message);
    }
  };
  
export const setUpSentryTags = settings => {
    if (settings?.serial) {
        Sentry.setTag('serial', settings.serial);
    }
    if (settings?.settings?.properties?.organisation) {
        Sentry.setTag('organisation', settings.settings.properties.organisation);
    }
    if (settings?.settings?.properties?.storeId) {
        Sentry.setTag('storeId', settings.settings.properties.storeId);
    }
    logger.info('Sentry initialized successfully');
};