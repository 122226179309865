import React from 'react';
import { PrimaryButton } from '../primary-button';
import { SecondaryButton } from '../secondary-button';
import { BasketCount as GenericBasketCount } from '../../stateless/basket-count';

import { FooterWrapper } from './wrappers/footer';
import { AdditionalFooter } from './wrappers/additional-footer';
import { MiniCartWrapper } from './wrappers/mini-cart';
import { BannerWarning } from '../banner-warning';

const Footer = ({
  hasUnavailableItems,
  nextText = 'Next',
  nextAction = null,
  isNextActionOnBottom = false,
  isBasketCountOnBottom = false,
  isQuantityControllerOnBottom = false,
  isPriceOnEnd = false,
  allowNextAction = true,
  nextActionClass = '',
  className = '',
  spacer = null,
  count = 0,
  enableCancelOrder = false,
  enableCustomCancelOrder = false,
  cancelOrderText = 'Cancel',
  showAccessibilityButton = false,
  showBottom = false,
  showBasketCount = true,
  showMiniCartSlider = false,
  isMiniCartEnabled = false,
  onBasketClick = () => {},
  total = null,
  OverwriteCartIcon = null,
  CustomSecondaryButton = SecondaryButton,
  CustomNextActionButton = PrimaryButton,
  CustomPreviousActionButton = PrimaryButton,
  BasketCount = GenericBasketCount,
  basketText = null,
  couponText = 'Enter offer',
  isCouponEnabled = false,
  showCouponButton = false,
  couponAction = () => {},
  isPreviousActionEnabled = false,
  allowPreviousAction = true,
  previousText = 'Back',
  previousAction = null,
  origin = '',
  onSelectCustomisationProduct = null,
  isAnimationTargetEnabled = false,
  isFooterStyleVariationEnabled = false,
  isDefaultButtonPaddingEnabled = true,
  termsAndConditionsButtonText = '',
  showTermsAndConditionsButton = false,
  setShowTermsAndConditionsModal = () => {},
  caloriesBannerEnabled = false,
  caloriesBannerDescription = '',
  t = () => {},
  isPreviousActionOnBottom = false,
  skipAction = null,
  isViewOrderDisabled = false,
  shouldSkipCustomerDetailsPath = false,
} = {}) => {
  const shouldShowCoupon = !spacer && isCouponEnabled && showCouponButton;

  const showFooter = previousAction || nextAction || enableCancelOrder;
  const showMiniCart = showMiniCartSlider && isMiniCartEnabled;

  const footerProps = {
    isViewOrderDisabled,
    nextText,
    nextAction,
    isNextActionOnBottom,
    isBasketCountOnBottom,
    isQuantityControllerOnBottom,
    isPriceOnEnd,
    allowNextAction,
    nextActionClass,
    className,
    spacer,
    count,
    enableCancelOrder,
    enableCustomCancelOrder,
    cancelOrderText,
    showAccessibilityButton,
    showBottom,
    showBasketCount,
    showMiniCartSlider,
    isMiniCartEnabled,
    onBasketClick,
    total,
    OverwriteCartIcon,
    CustomSecondaryButton,
    CustomNextActionButton,
    CustomPreviousActionButton,
    BasketCount,
    basketText,
    couponText,
    isCouponEnabled,
    showCouponButton,
    couponAction,
    isPreviousActionEnabled,
    allowPreviousAction,
    previousText,
    previousAction,
    origin,
    onSelectCustomisationProduct,
    isAnimationTargetEnabled,
    isFooterStyleVariationEnabled,
    shouldShowCoupon,
    hasUnavailableItems,
    isDefaultButtonPaddingEnabled,
    termsAndConditionsButtonText,
    showTermsAndConditionsButton,
    setShowTermsAndConditionsModal,
    isPreviousActionOnBottom,
    skipAction,
    t,
    shouldSkipCustomerDetailsPath,
  };

  return (
    <>
      {caloriesBannerEnabled && caloriesBannerDescription !== '' && (
        <BannerWarning
          className="items-center p-2 pb-8 m-8 text-center bg-white shadow-lg md:p-4 font-card banner-warning"
          backgroundColor="bg-white"
          description={caloriesBannerDescription}
        />
      )}
      {showMiniCart && <MiniCartWrapper {...footerProps} />}
      {showBottom && <AdditionalFooter {...footerProps} />}
      {showFooter && <FooterWrapper {...footerProps} />}
    </>
  );
};

export { Footer };
