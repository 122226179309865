import React, { useEffect, useState } from 'react';
import { validate } from '@fingermarkglobal/validation';
import Plus from 'heroicons/solid/plus.svg';
import Minus from 'heroicons/solid/minus.svg';
import Pencil from 'heroicons/solid/pencil.svg';
import Delete from 'heroicons/solid/x.svg';
import Ban from 'heroicons/solid/ban.svg';
import { IconButton } from '../icon-button';
import { useOffersAndRewardsMenu } from '../../../hooks/restaurant/menu';

const CartProductLine = ({
  product = null,
  onUpdateHandler = () => () => {},
  onEditProductHandler = () => () => {},
  t = () => {},
} = {}) => {
  validate({ name: 'CartProductLine', parameters: { product } });

  const {
    productId,
    name,
    count,
    add,
    remove,
    removeDisabled = false,
    addDisabled = false,
    isAvailable = true,
    removeAll,
    editDisabled = false,
    filterName = {},
  } = product;

  const [isOffer, setIsOffer] = useState(false);

  const { current: currentCount } = count;

  const { getPromoItem, offersAndRewards } = useOffersAndRewardsMenu();
  const promoItem = getPromoItem({ productId });

  useEffect(() => {
    const isSelectedItemAnOffer = !!offersAndRewards?.offers?.find(
      offer => offer.promoId === product.providerData.PromoId,
    );
    setIsOffer(isSelectedItemAnOffer);
  }, [offersAndRewards, product, setIsOffer]);

  const filterNameText = promoItem?.name ? null : filterName?.text && `(${filterName?.text})`;

  return (
    <div
      className={`flex flex-col justify-between rounded p-4 w-full ${!isAvailable && 'bg-red-100'}`}
    >
      {!isAvailable && (
        <div className="flex items-center justify-center space-x-4 text-4xl text-center text-red-600">
          <Ban className="w-auto h-12"></Ban>
          <span>{t('cartProductUnavailableGeneral')}</span>
        </div>
      )}
      <div className="flex flex-col p-4">
        <div className="flex justify-between w-full">
          <div className="flex items-center justify-center">
            <div className="text-xl font-bold">
              {promoItem?.name ?? name.text} {filterNameText}
            </div>
            <div data-test="product-quantity-count" className="ml-4 text-xl min-w-12">
              x {currentCount}
            </div>
          </div>

          <div className="flex items-start mx-4 space-x-4">
            {product.type !== 'simple' && (
              <IconButton
                data-test="button-product-edit"
                className="flex items-center justify-center w-12 h-12 p-2 pr-4 border-2 border-primary disabled:opacity-50 focus:outline-none text-mini-cart-button"
                onClick={onEditProductHandler(product)}
                disabled={!isAvailable || editDisabled}
                Icon={Pencil}
              />
            )}

            <IconButton
              data-test="button-product-quantity-remove"
              className="flex items-center justify-center w-12 h-12 p-2 pr-4 border-2 border-primary disabled:opacity-50 focus:outline-none text-mini-cart-button"
              onClick={onUpdateHandler(remove)}
              disabled={!isAvailable || removeDisabled}
              Icon={Minus}
            />

            <IconButton
              data-test="button-product-quantity-add"
              className="flex items-center justify-center w-12 h-12 p-2 pr-4 border-2 border-primary disabled:opacity-50 focus:outline-none text-mini-cart-button"
              onClick={onUpdateHandler(add)}
              disabled={!isAvailable || addDisabled || isOffer}
              Icon={Plus}
            />

            <IconButton
              data-test="button-product-remove"
              className="flex items-center justify-center w-12 h-12 p-2 pr-4 text-white border-2 border-primary disabled:opacity-50 focus:outline-none bg-primary"
              onClick={onUpdateHandler(removeAll, true)}
              Icon={Delete}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { CartProductLine };
